@if (!isReadOnly) {
  <div>
    @if (!isPredefinedReport) {
      <dpa-tooltip
        dpaTabEvent
        [attr.aria-disabled]="!shouldRefreshPreview"
        [tooltipText]="(shouldRefreshPreview ? 'COMMON_ACTIONS.REFRESH_PREVIEW' : 'REPORT_CUSTOMIZE.DISABLED_REFRESH_MESSAGE') | translate"
        [attr.aria-label]="
          (shouldRefreshPreview ? 'COMMON_ACTIONS.REFRESH_PREVIEW' : 'REPORT_CUSTOMIZE.DISABLED_REFRESH_MESSAGE') | translate
        "
        (keyup.enter)="onPageChange(pagedRequest)"
        role="button"
      >
        <button
          [disabled]="!shouldRefreshPreview"
          (click)="onPageChange(pagedRequest)"
          class="btn btn-primary btn-sm mt-x2"
        >
          {{ 'COMMON_ACTIONS.REFRESH_PREVIEW' | translate }}
        </button>
      </dpa-tooltip>
    }
    @if (isLoadingPreview) {
      <div class="mv-x1">
        <span>
          {{ 'REPORT_CUSTOMIZE.LOADING_RESULTS' | translate }}
        </span>
      </div>
    }
    @if (totalCount && !isLoadingPreview) {
      <div class="mb-x2 mt-x1">
        <span>
          {{ customPreviewMessage ?? 'REPORT_CUSTOMIZE.REPORT_FILTERS_PREVIEW_RESULTS_COUNT' | translate: { totalCount: totalCount } }}
        </span>
        <dpa-from-now
          [timestamp]="refreshedAt"
          [formatString]="'COMMON_MESSAGES.REFRESHED'"
          class="mt-x2"
        />
      </div>
    }
  </div>
}
<div>
  @if (!isPredefinedReport && isPreviewTimedout) {
    <clr-alert
      clrAlertType="warning"
      [clrAlertClosable]="true"
      class="mt-x2"
    >
      <clr-alert-item class="alert-item">
        <span class="alert-text">
          {{ (isReadOnly ? 'REPORT_CUSTOMIZE.TIMEOUT_WARNING_READONLY' : 'REPORT_CUSTOMIZE.TIMEOUT_WARNING') | translate }}
        </span>
        @if (isReadOnly) {
          <a
            (click)="runReport()"
            (keyup.enter)="runReport()"
          >
            {{ 'COMMON_ACTIONS.RUN' | translate }}
          </a>
        }
      </clr-alert-item>
    </clr-alert>
  }
</div>
<div
  [class.read-only]="isReadOnly"
  class="result-table"
>
  @if (rootCustomAttribute) {
    <button
      (click)="openSensorColumnSelectorModal()"
      class="btn btn-link"
    >
      {{ 'CUSTOM_ATTRIBUTE.ADD_CUSTOM_ATTRIBUTES' | translate }}
    </button>
  }
  @if (canViewDataGrid()) {
    @if (filterResults) {
      <dpa-data-grid
        [loading]="isLoadingPreview"
        [response]="filterResults"
        [sortOns]="[]"
        [selectable]="{ enabled: false }"
        [pagination]="false"
        [enableColumnSelector]="!isReadOnly"
        [enableColumnHiding]="false"
        [availableColumns]="allColumns"
        [selectedColumns]="dataGridColumns"
        (selectedColumnsChange)="onSelectedColumnsChange($event)"
        (pageChange)="onPageChange($event)"
      >
        <div dpaDataGridColumnDefault>
          <ng-template
            dpaDataGridCell
            let-dataItem
            let-column="column"
          >
            @if (cellTemplatesByColumnValue[column.value]) {
              <ng-container
                [ngTemplateOutlet]="cellTemplatesByColumnValue[column.value]"
                [ngTemplateOutletContext]="{ dataItem: dataItem, dataGridColumn: column }"
              />
            } @else {
              <dpa-decorated-value
                [value]="dataItem[column.value]"
                [valueDecorator]="column?.detail?.valueDecorator"
              />
            }
          </ng-template>
        </div>
        <ng-template
          dpaDataGridColumnSelectorDetail
          let-label
          let-detail="detail"
        >
          <dpa-column-selector-detail
            [label]="label"
            [detail]="detail"
          />
        </ng-template>
      </dpa-data-grid>
    }
  } @else {
    <!-- We have three scenarios here
    1. No columns are selected - display empty datagrid with "Add first column" message
    2. Preview is still not loaded - display empty datagrid with "Refresh preview" message
    3. Preview results is empty - display empty datagrid with "No records" message
    -->
    <dpa-data-grid
      [loading]="false"
      [selectable]="{ enabled: false }"
      [noResultsText]="getNoResultsText() | translate"
      [showEmptyGridWithColumnHeader]="true"
      [enableColumnSelector]="!dataGridColumns?.length || (!isReadOnly && !isPredefinedReport)"
      [enableColumnHiding]="false"
      [availableColumns]="allColumns"
      [selectedColumns]="dataGridColumns"
      (selectedColumnsChange)="onSelectedColumnsChange($event)"
      class="empty-grid mt-x1"
    >
      <ng-template
        dpaDataGridColumnSelectorDetail
        let-label
        let-detail="detail"
      >
        <dpa-column-selector-detail
          [label]="label"
          [detail]="detail"
        />
      </ng-template>
    </dpa-data-grid>
  }
</div>
@if (isCustomAttributeSelectorModalOpen) {
  <dpa-custom-attribute-selector
    [parentAttributeName]="rootCustomAttribute.attributeName"
    [isMultiSelect]="true"
    [selectedCustomAttributes]="selectedCustomAttributes"
    (selectAttributes)="addSensorColumns($event)"
    (closeModal)="closeSensorColumnSelectorModal()"
  />
}
