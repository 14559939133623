<div class="rule-text-wrapper">
  <div class="key">
    <dpa-tooltip
      [tooltipTemplate]="keyTooltipTmpl"
      class="column-label"
    >
      {{ key }}
      <div class="ml-x1">
        @if (rule?.attribute | isAttributeNormalized: allColumnsByName : columnToggleFilterMap) {
          <span class="label mr-0">
            {{ 'COMMON_MESSAGES.NORMALIZED' | translate }}
          </span>
        }
        @if (rule?.attribute | isAttributeRaw: columnToggleFilterMap) {
          <span class="label mr-0">
            {{ 'COMMON_MESSAGES.RAW' | translate }}
          </span>
        }
      </div>
    </dpa-tooltip>
  </div>
  <div class="condition">
    {{ condition | uppercase }}
  </div>
  <div class="value">
    @if (valueCount === 1) {
      {{ value }}
    }

    <div
      [hidden]="valueCount === 1"
      (click)="handleValuesClick()"
      class="values-wrapper ph-2 clickable"
    >
      {{ valueCount }}
      {{ 'COMMON_MESSAGES.VALUES' | translate }}
    </div>
  </div>
</div>

<ng-template #keyTooltipTmpl>
  <div class="key-tooltip-wrapper">
    <div>
      @if (keyTooltipInfo?.entity) {
        <span>
          {{ this.keyTooltipInfo?.entity }}
          <cds-icon
            shape="arrow"
            size="16"
          />
        </span>
      }
      <span>
        {{ this.keyTooltipInfo?.label }}
      </span>
    </div>
  </div>
</ng-template>
