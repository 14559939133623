<div class="filters-container">
  <button
    #filterBtn
    [class.active]="isFilterActive"
    (click)="toggleFilterStatus()"
    class="filter-btn clickable mr-x2"
  >
    <cds-icon
      shape="filter"
      class="filter-icon"
    />
    <span class="filter-text">{{ 'COMMON_ACTIONS.FILTER' | translate | uppercase }}</span>
    <cds-icon
      [attr.direction]="isFilterActive ? 'up' : 'down'"
      shape="angle"
      class="angle-icon"
    />
    @if (filtersList.length) {
      <div class="blue-dot"></div>
    }
  </button>
  @if (isFilterActive) {
    <div
      #filterContainer
      class="list-box"
    >
      <ng-container *ngTemplateOutlet="filterTemplate" />
    </div>
  }
  <div class="btn-container">
    @for (filterKey of filtersList; track filterKey) {
      <div class="btn-filter mr-x1">
        <div class="btn-text-container">
          <dpa-ellipsis-with-tooltip class="mr-x1">{{ activeFilters[filterKey].label }}</dpa-ellipsis-with-tooltip>
          <button
            (click)="onRemoveFilter(filterKey)"
            role="button"
            tabindex="0"
            class="pill-btn p-x0"
          >
            <cds-icon
              shape="times"
              class="clickable"
            />
          </button>
        </div>
      </div>
    }
  </div>
  @if (filtersList.length) {
    <button
      (click)="onClearAllFilters()"
      class="btn btn-link"
      tabindex="0"
      role="button"
    >
      {{ 'REPORT_CUSTOMIZE.REPORT_FILTERS_CLEAR_ALL' | translate }}
    </button>
  } @else {
    <span class="no-filters-text">{{ 'COMMON_MESSAGES.NO_FILTERS_APPLIED' | translate }}</span>
  }
</div>
