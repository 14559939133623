<div [ngClass]="isInlineFilter ? 'inline-filter-text-view' : 'filter-group-text-view'">
  <div class="rules-text">
    @if (isInlineFilter) {
      @if (filteredRules?.length) {
        <div class="rule-number">
          <cds-icon
            solid
            shape="filter"
            size="12"
          />
        </div>
      }
      <!-- Shows complete filter explicitly "App Version includes (all)" -->
      @for (column of columnsToShowIncludesAll; track column; let columnIndex = $index) {
        <div>
          @if (columnIndex > 0) {
            <strong class="mh-x1">
              {{ 'FILTER_VALUE.AND' | translate }}
            </strong>
          }
          <div class="includes-all-text">
            {{ column?.attribute.fullyQualifiedName | columnLabel: allColumnsByName : columnToggleFilterMap }}
            <ng-container *ngTemplateOutlet="attributeBadge; context: { attribute: column?.attribute?.fullyQualifiedName }" />
            <span class="rule-condition">{{ 'COMMON_MESSAGES.INCLUDES' | translate | uppercase }}</span>
            <span>{{ 'COMMON_MESSAGES.ALL' | translate }}</span>
          </div>
        </div>
      }
    }

    <ul class="rule-set root">
      @if (!isInlineFilter) {
        @if (columnsToShowIncludesAll.length > 0 && ruleGroup?.getFilterCount(allColumnsByName) === 0) {
          @for (column of columnsToShowIncludesAll; track column; let columnIndex = $index) {
            <li>
              <div class="rule-number">
                {{ columnIndex + 1 }}
              </div>

              <div class="rule-text-container-wrapper">
                @if (columnIndex > 0) {
                  <span class="rule-operator and">
                    {{ 'FILTER_VALUE.AND' | translate }}
                  </span>
                }
                <div class="includes-all-text">
                  {{ column?.attribute.fullyQualifiedName | columnLabel: allColumnsByName : columnToggleFilterMap }}
                  <ng-container *ngTemplateOutlet="attributeBadge; context: { attribute: column?.attribute?.fullyQualifiedName }" />
                  <span class="rule-condition">{{ 'COMMON_MESSAGES.INCLUDES' | translate | uppercase }}</span>
                  <span>{{ 'COMMON_MESSAGES.ALL' | translate }}</span>
                </div>
              </div>
            </li>
          }
        }
      }
      <ng-container
        *ngTemplateOutlet="
          ruleGroupTmpl;
          context: {
            ruleGroup: ruleGroup,
            isRoot: true
          }
        "
      />
    </ul>
  </div>
  <div class="filter-text-actions">
    @if (showEdit) {
      <dpa-tooltip
        [attr.aria-label]="'COMMON_ACTIONS.EDIT' | translate"
        [tooltipText]="'COMMON_ACTIONS.EDIT' | translate"
        [focusEnabled]="true"
        (click)="toggleEdit()"
        (keyup.enter)="toggleEdit()"
        role="tooltip"
      >
        <a class="action-icon-container circle">
          <cds-icon shape="pencil" />
        </a>
      </dpa-tooltip>
    }
    @if (hasRules()) {
      <dpa-tooltip
        [attr.aria-label]="'COMMON_ACTIONS.COPY' | translate"
        [tooltipText]="'COMMON_ACTIONS.COPY' | translate"
        [focusEnabled]="true"
        (click)="copyRules()"
        (keyup.enter)="copyRules()"
        role="tooltip"
      >
        <a class="action-icon-container circle">
          <cds-icon shape="copy" />
        </a>
      </dpa-tooltip>
    }
    <dpa-tooltip
      [attr.aria-label]="showLess ? SHOW_VALUES : HIDE_VALUES"
      [tooltipText]="showLess ? SHOW_VALUES : HIDE_VALUES"
      [focusEnabled]="true"
      (click)="toggleRuleView()"
      (keyup.enter)="toggleRuleView()"
      role="tooltip"
    >
      <a class="action-icon-container circle">
        @if (!showLess) {
          <cds-icon
            shape="eye-hide"
            class="is-solid eye-icon"
          />
        } @else {
          <cds-icon
            shape="eye"
            class="is-solid eye-icon"
          />
        }
      </a>
    </dpa-tooltip>
  </div>
</div>

<ng-template
  #ruleTmpl
  let-rule="rule"
>
  <div
    *dpaDelayRender="1"
    class="rule-text"
  >
    <div
      *ngLet="{
        ruleStatus: getRuleStatus(rule),
        ruleInfo: getRuleInfo(rule)
      } as details"
      class="rule-value pr-x1"
    >
      @if (details.ruleStatus === RULE_STATUS.EMPTY) {
        <a
          [class.clickable]="!readOnly"
          (click)="onFilterRuleClick(rule, $event)"
          (keyup.enter)="onFilterRuleClick(rule, $event)"
          class="empty-rule"
        >
          {{ 'COMMON_ERRORS.EMPTY_RULE' | translate }}
        </a>
      }
      @if (details.ruleStatus === RULE_STATUS.INVALID) {
        <a
          [class.clickable]="!readOnly"
          (click)="onFilterRuleClick(rule, $event)"
          (keyup.enter)="onFilterRuleClick(rule, $event)"
          class="text-danger"
        >
          <span>
            {{ rule.attribute | columnLabel: allColumnsByName : columnToggleFilterMap }}
            <ng-container *ngTemplateOutlet="attributeBadge; context: { attribute: rule?.attribute }" />
            {{ details.ruleInfo.condition }}
          </span>
        </a>
      }
      @if ([RULE_STATUS.DRAFT, RULE_STATUS.VALID].includes(details.ruleStatus)) {
        <div class="rule-value-text">
          <span class="attribute-name">
            {{ rule.attribute | columnLabel: allColumnsByName : columnToggleFilterMap }}
            <ng-container *ngTemplateOutlet="attributeBadge; context: { attribute: rule?.attribute }" />
          </span>
          <span class="rule-condition">
            {{ details.ruleInfo.condition | uppercase }}
          </span>
          @if (!showLess || details.ruleInfo.valueCount === 1) {
            <span>
              {{ details.ruleInfo.translatedValue }}
            </span>
          } @else {
            <span class="rule-value-count">{{ details.ruleInfo.valueCount }} {{ 'COMMON_MESSAGES.VALUES' | translate }}</span>
          }
        </div>
      }
    </div>
  </div>
</ng-template>

<ng-template
  #ruleGroupTmpl
  let-ruleGroup="ruleGroup"
  let-isRoot="isRoot"
>
  @if (showRuleSet()) {
    <ng-container>
      @for (rule of ruleGroup?.rules; track rule; let ruleIndex = $index) {
        <li>
          @if (isRoot) {
            <div class="rule-number">
              {{ ruleIndex + 1 }}
            </div>
          }
          @if (ruleIndex >= 0 || columnsToShowIncludesAll?.length) {
            <div class="rule-text-container-wrapper">
              @if (displayRuleOperator(rule) && ruleIndex > 0) {
                <span
                  [class.and]="ruleGroup.operator === RuleGroupOperator.AND"
                  [class.or]="ruleGroup.operator === RuleGroupOperator.OR"
                  class="rule-operator"
                >
                  {{ 'FILTER_VALUE.' + ruleGroup.operator | translate }}
                </span>
              }
              @if (isRuleGroup(rule)) {
                <span class="open-bracket">(</span>
                <div class="ml-x3">
                  <ul class="rule-set">
                    <ng-container *ngTemplateOutlet="ruleGroupTmpl; context: { ruleGroup: rule }" />
                  </ul>
                </div>
                <span class="close-bracket">)</span>
              }
            </div>
          }
          @if (!isRuleGroup(rule) && displayRule(rule)) {
            <div class="rule-text-container">
              <ng-container *ngTemplateOutlet="ruleTmpl; context: { rule: rule }" />
              @if (rule.isCustomAttributeFilterRule()) {
                @defer (on timer(1ms)) {
                  <span class="open-bracket">(</span>
                  <div class="ml-x3">
                    <ul class="rule-set">
                      <ng-container *ngTemplateOutlet="ruleGroupTmpl; context: { ruleGroup: rule.customAttributeRuleGroup }" />
                    </ul>
                  </div>
                  <span class="close-bracket">)</span>
                }
              }
            </div>
          }
        </li>
      } @empty {
        <li class="pl-x2 empty-rule">
          {{ 'COMMON_ERRORS.EMPTY_RULE' | translate }}
        </li>
      }
    </ng-container>
  }
</ng-template>

<ng-template
  #attributeBadge
  let-attribute="attribute"
>
  @if (attribute | isAttributeNormalized: allColumnsByName : columnToggleFilterMap) {
    <span class="label mr-0 special">
      {{ 'COMMON_MESSAGES.NORMALIZED' | translate }}
    </span>
  }
  @if (attribute | isAttributeRaw: columnToggleFilterMap) {
    <span class="label mr-0 special">
      {{ 'COMMON_MESSAGES.RAW' | translate }}
    </span>
  }
</ng-template>
